import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneNumberInput = () => {
  const [phone, setPhone] = useState('');

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  return (
    <div>
      <h2>Enter your phone number</h2>
      <PhoneInput
        country={'us'} // Set default country code
        value={phone}
        onChange={handlePhoneChange}
        inputStyle={{
          width: '300px',
          height: '40px',
          fontSize: '16px',
          padding: '10px',
          borderRadius: '5px',
          border: '1px solid #ccc',
        }}
        buttonStyle={{
          borderRadius: '5px 0 0 5px',
          border: '1px solid #ccc',
        }}
      />
      <p>Phone number: {phone}</p>
    </div>
  );
};

export default PhoneNumberInput;
