import React from "react";
import "../Styles/Home.css";
import frothdeskPage1 from "../Assets/FrothDeskPAge1.png";
import img1 from "../Assets/img1.png";
import img2 from "../Assets/img2.png";
import img3 from "../Assets/img3.png";
import img4 from "../Assets/img4.png";
import img5 from "../Assets/img5.png";
import img6 from "../Assets/img6.png";
import home3 from "../Assets/home3.png";
import home4 from "../Assets/home4.png";
import home5 from "../Assets/home5.png";
import frothLogo from "../Assets/frothlogo.png";
import Testimonials from "./Testimonals";
import { useNavigate } from "react-router-dom";

const Home = () => {

  const navigate = useNavigate();
  
  const handleContact = () => {
    window.open('/ContactUs', '_blank');
    navigate("/");
  };

  return (
    <div className="container-fluid">
      <div className="Home-Content">
        <div className="row d-flex align-items-center">
          <div className="form-container col-md-6">
            <div className="Main-Content">
              <h1>
                <span className="spanBlack">
                  Streamline Your Help
                  <br />
                  Desk Operations with
                </span>
                <br />
                <span className="spanRed">FrothDesk</span>
              </h1>
            </div>
            <div className="Sub-Content">
              <p>
                Eliminate the oversight of inquiries and the redundancy of
                responses, streamlining the communication process and enhancing
                the transparency of information flows.
              </p>
            </div>
            <button
              id="contact"
              className="btn btn-danger"
              onClick={handleContact}
            >
              Contact Us
            </button>
          </div>
          <div className="form-container col-md-6">
            <img
              className="img-fluid"
              id="frothcrm-img"
              src={frothdeskPage1}
              alt="FrothDesk Page 1"
            />
          </div>
        </div>
      </div>
      <div className="Home-Content2">
        <div id="Container">
          <h5 className="my-5">
            <span className="span-Red">-</span>
            <span className="spanFeature">The Features</span>
          </h5>
          <h3 className="text-center mb-4">
            Classically addressing{" "}
            <span className="span-Red">
              IT support, orchestrating interactions, FrothDesk functions
            </span>{" "}
            as the heart of your communication network.
          </h3>
          <div className="row" style={{ marginTop: "3rem" }}>
            <div className="col-md-4" id="card-Content">
              <img src={img1} alt="Feature 1" className="mb-3 card-imgs" />
              <h5>
                Abundant in Features &<br />
                Exceptionally<span className="span-Red"> Adaptable</span>
              </h5>
              <p>
                FrothDesk offers remarkable versatility and seamlessly aligns
                with your organization and its unique processes.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img2} alt="Feature 2" className="mb-3 card-imgs" />
              <h5>
                <span className="span-Red">Time-Tested &</span>
                <br /> Contemporary
              </h5>
              <p>
                FrothDesk introduces a brand-new service portal, combining
                reliability with contemporary design.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img3} alt="Feature 3" className="mb-3 card-imgs" />
              <h5>
                Self-Hosted or <br />
                <span className="span-Red">Cloud-Based</span>
              </h5>
              <p>
                Choose to either independently manage your system or entrust it
                to us. With FrothDesk, you maintain total flexibility.
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: "3rem" }}>
            <div className="col-md-4" id="card-Content">
              <img src={img4} alt="Feature 1" className="mb-3 card-imgs" />
              <h5>
                Workplaces or
                <br />
                <span className="span-Red">Remote Setups</span>
              </h5>
              <p>
                Enable your team to collaborate efficiently and stay organized,
                whether they're working from the office or a home office.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img5} alt="Feature 2" className="mb-3 card-imgs" />
              <h5>
                <span className="span-Red">Forward</span>-looking
                <br />& Safeguarded
              </h5>
              <p>
                FrothDesk continues to receive security updates and fresh
                features. That's our commitment from the outset.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img6} alt="Feature 3" className="mb-3 card-imgs" />
              <h5>
                Feature-rich <br />
                <span className="span-Red">and Flexible</span>
              </h5>
              <p>
                FrothDesk is extremely versatile and adapts perfectly to your
                organisation and processes..
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-Content3">
        <div className="row d-flex align-items-center">
          <div className="form-container col-md-6">
            <div className="Main-Content">
              <h2>Empower Your Agents with the Tools They Need to Succeed</h2>
            </div>
            <div className="Sub-Content">
              <p>
                Provide your help desk agents with the tools they need to excel
                in their roles. Our intuitive ticketing system offers a
                user-friendly interface, powerful search capabilities, and
                comprehensive knowledge base access, empowering agents to
                resolve customer issues quickly and effectively.
              </p>
            </div>
          </div>
          <div className="form-container col-md-6">
            <img
              className="img-fluid"
              id="frothcrm-img"
              src={home3}
              alt="FrothDesk Page 1"
            />
          </div>
        </div>
      </div>

      <div className="Home-Content4">
        <div className="row d-flex align-items-center">
          <div className="form-container col-md-6">
            <img
              className="img-fluid"
              id="frothcrm-img"
              src={home4}
              alt="FrothDesk Page 1"
            />
          </div>
          <div className="form-container col-md-6">
            <div className="Main-Content">
              <h2>Embrace Scalability for Your Growing Support Needs</h2>
            </div>
            <div className="Sub-Content">
              <p>
                Our ticketing system is designed to scale seamlessly with your
                growing support needs. As your customer base expands, our system
                will adapt to handle the increased volume of support tickets
                without compromising efficiency or performance
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-Content5">
        <div className="row d-flex align-items-center">
          <div className="form-container col-md-6">
            <div className="Main-Content">
              <h2>Collaborate Seamlessly for Enhanced Customer Satisfaction</h2>
            </div>
            <div className="Sub-Content">
              <p>
                Foster a culture of collaboration and communication within your
                help desk team with our collaborative ticketing system. Assign
                tickets, share updates, and track progress seamlessly, ensuring
                that every customer issue receives the attention it deserves.
              </p>
            </div>
          </div>
          <div className="form-container col-md-6">
            <img
              className="img-fluid"
              id="frothcrm-img"
              src={home5}
              alt="FrothDesk Page 1"
            />
          </div>
        </div>
      </div>
      <div className="Home-Content6">
        <span className="spanBlack">FrothDesk</span>
        <div 
        className="card col-md-5" style={{border:'none',padding:'0',margin:'0'}}
        >
          <iframe
            // class="embed-responsive-item"
            src="https://www.youtube.com/embed/pDvOdtr4kLk?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Ffrothdesk.com&widgetid=1"
            title="YouTube video player"
            frameBorder="0"
            // width="550"
            height="300"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="Home-Content7">
        <span className="spanBlack">Testimonials</span>
        <Testimonials />
      </div>
      <div className="Home-Content4">
        <div className="row d-flex align-items-center">
        <div className="form-container col-md-2"></div>
          <div className="form-container col-md-4">
            <img
              className="img-fluid"
              width="504"
              height="434"
              src={frothLogo}
              alt="FrothDesk Page 1"
            />
          </div>
          <div className="form-container col-md-6" style={{textAlign:'center'}}>
            <div className="Main-Content">
              <h2>
                Let's <span className="span-Red">talk</span> about it.
              </h2>
            </div>
            <div className="Sub-Content">
              <p>
                We are happy to take half an hour to discuss how Froth-Desk can
                make your business day-to-day easier.
              </p>
            </div>
            <button
              id="contact"
              className="btn btn-danger"
              onClick={handleContact}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
