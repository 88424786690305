import React from 'react';
import '../Styles/terms.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Use</h1>
      <p>These Terms of Use (“Terms”) govern your use of the IT services provided by Robotico Digital (“we,” “us,” or “our”) through our website <a style={{textDecoration:'none'}} href='https://www.roboticodigital.com'>https://www.roboticodigital.com</a> (“Website”). By accessing or using our Website, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use our Website.</p>

      <h4>1. Use of Services</h4>
      <p>1.1. Eligibility: You must be at least 18 years old to use our services. By using our services, you represent and warrant that you are at least 18 years old.</p>
      <p>1.2. Account: Some services may require you to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

      <h4>2. Intellectual Property</h4>
      <p>2.1. Ownership: All content, including but not limited to text, graphics, logos, images, and software, available on our Website is the property of Robotico Digital or its licensors and is protected by intellectual property laws.</p>
      <p>2.2. License: Subject to these Terms, we grant you a limited, non-exclusive, non-transferable license to use our services for your personal or internal business purposes.</p>

      <h4>3. Prohibited Activities</h4>
      <p>You agree not to:</p>
      <ul>
        <li>Use our services for any unlawful purpose.</li>
        <li>Attempt to gain unauthorized access to our systems or networks.</li>
        <li>Interfere with the proper functioning of our services.</li>
        <li>Modify, adapt, translate, reverse engineer, or decompile any portion of our Website.</li>
      </ul>

      <h4>4. Privacy</h4>
      <p>4.1 Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using our services, you consent to the collection and use of your personal information as described in our Privacy Policy.</p>

      <h4>5. Disclaimer of Warranties</h4>
      <p>5.1 Our services are provided on an “as is” and “as available” basis without any warranties, express or implied. We do not warrant that our services will be uninterrupted, secure, or error-free.</p>

      <h4>6. Limitation of Liability</h4>
      <p>6.1 In no event shall Robotico Digital be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our services.</p>

      <h4>7. Indemnification</h4>
      <p>.1 You agree to indemnify and hold harmless Robotico Digital, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in connection with your use of our services.</p>

      <h4>8. Governing Law</h4>
      <p>8.1 These Terms shall be governed by and construed in accordance with the laws of Karnataka, India, without regard to its conflict of law provisions.</p>

      <h4>9. Changes to Terms</h4>
      <p>9.1 We reserve the right to modify or update these Terms at any time without prior notice. Your continued use of our services after any changes to these Terms constitutes acceptance of such changes.</p>

      <h4>10. Contact Us</h4>
      <p>10.1 If you have any questions or concerns about these Terms, please write to us at <a style={{textDecoration:'none'}} href='mailto:info@roboticodigital.com'>info@roboticodigital.com</a> or refer to our contact page for more details.</p>
    </div>
  );
};

export default TermsAndConditions;
