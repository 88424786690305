import React from 'react'

const PageNotFound = () => {
  return (
    <div style={{marginTop:'3rem'}}>
        <h1>Sorry! Page not found</h1>
    </div>
  )
}

export default PageNotFound