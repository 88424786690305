import React,{useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import PageRouter from '../PageRouter';
import Header from './Header';
import Footer from './Footer';
import '../Styles/Main.css';
import FrothHeader from './FrothHeader';
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const Main = () => {
  const location = useLocation();
  const hideHeader = location.pathname === '/ContactUs' || location.pathname === '/FrothTech';

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    try {
      const response = await fetch("https://frothdesk.com/crm_login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({}).toString(),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken",data?.access_token)
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };


  return (
    <div className="app-container">
      {!hideHeader ? <Header /> : <FrothHeader />}
      <div className="content-container">
        <div className="container Router-Pages">
          <PageRouter />
          <ToastContainer />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Main;
