import React, { useState, useEffect } from "react";
import { apiUrl } from "../Pages/Config";
import "../Styles/Pricing.css"; // Assuming you put the CSS in this file

const PricingPage = () => {
  const [loading, setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState(null);

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
          const productResponse = await fetch(
            `${apiUrl}fetchProductDetails/FrothDesk`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (productResponse.ok) {
            const productData = await productResponse.json();
            setProductDetails(productData);
          } else {
            alert("Failed to fetch product details. Please try again.");
          }
      } catch (error) {
        console.error("Error during login or fetching product details:", error);
        alert("Failed to login or fetch product details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, []);

  const handleContact = () => {
    localStorage.setItem("plan","Enterprise Plan")
    window.open("/ContactUs", "_blank");
  };

  return (
    <div
      className="container"
      id="pricing-page"
      style={{ marginLeft: ".5rem", marginTop: "1rem" }}
    >
      <div className="text-center" id="header-part">
        <h1 className="plan-title">Pricing Plan</h1>
        <span class="hr-line"></span>
        <div
          className="form-check form-switch"
          style={{ paddingLeft: "0rem", display: "flex", gap: "5%" }}
        >
          {/* Toggle switch can be added here */}
        </div>
      </div>
      {!loading ? (
        <div
          className="row row-cols-1 row-cols-md-3 mb-3 text-center"
          style={{ display: "flex",gap:'5rem' }}
        >
          <div className="col-md-7 text-justify">
            <h5 style={{ lineHeight: "1.6" }}>
              <span className="tilte-content">FrothDesk</span> understands that
              every business has unique needs. That's why we offer special,
              customized pricing plans tailored to fit your specific
              requirements. Whether you're a startup or an established
              enterprise, our flexible options ensure you get the best value.
              Contact us today to find out how we can create a plan just for
              you!
            </h5>
            <h5 style={{ lineHeight: "1.6" }}>
              Explore the features and benefits of our advanced ticketing system
              designed to enhance your customer support experience. Discover how
              FrothDesk can streamline your processes and improve customer
              satisfaction.
            </h5>
            <ul className="custom-bullet-list">
              <li>
                Helps you organize and track customer support interactions.
              </li>
              <li>Provides context for resolving customer issues faster.</li>
              <li>
                Automates repetitive tasks and enforces business policies.
              </li>
              <li>
                FrothDesk can answer simple customer questions and deflect
                tickets.
              </li>
              <li>
                This helps you track your support performance and identify areas
                for improvement.
              </li>
              <li>
                FrothDesk can help you optimize your team's scheduling and
                performance.
              </li>
              <li>
                FrothDesk can help in improving the quality of your customer
                support interactions.
              </li>
              <li>
                Allow you to set your support availability and manage customer
                expectations.
              </li>
              <li>Help you measure customer satisfaction with your support.</li>
              <li>
                Help you set and track expectations for your support response
                times.
              </li>
              <li>
                Can provide more sophisticated AI-powered features, such as
                sentiment analysis and chatbots.
              </li>
              <li>Help you comply with data privacy regulations.</li>
              <li>Routes tickets to the most qualified agents.</li>
              <li>
                Allow you to define different levels of access and permissions
                for your agents.
              </li>
              <li>
                Provide agents with the information they need to resolve tickets
                quickly.
              </li>
            </ul>
          </div>
          <div className="col">
            <div
              className="card mb-4 rounded-3 shadow-sm"
              style={{ margin: "10px", padding: "0" }}
            >
              <div
                className="card-header py-3"
                style={{ backgroundColor: "#54595F" }}
              >
                <h4
                  className="my-0 fw-normal text-white text-center"
                  id="enterprise-plan-title"
                >
                  ENTERPRISE PLAN
                </h4>
                <div className="corner-ribbon corner-ribbon-enterprise">
                  <span>PROFESSIONAL</span>
                </div>
              </div>
              <div className="card-body text-center">
                <ul
                  className="list-unstyled mt-3 mb-4"
                  id="enterprise-plan-description"
                >
                  {productDetails?.Enterprise_Plan?.description
                    .split("|")
                    .map((item, index) => (
                      <li key={index}>
                        {item}
                        {index <
                          productDetails?.Enterprise_Plan?.description.split(
                            "|"
                          ).length -
                            1 && <hr id="list-line" />}
                      </li>
                    ))}
                </ul>
                <button
                  type="button"
                  id="buy-now"
                  className="btn"
                  onClick={handleContact}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
      )}
    </div>
  );
};

export default PricingPage;
